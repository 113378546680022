html,
body {
  height: auto !important;
  background-color: rgb(234, 234, 234) !important;
}

.ui.container {
  width: 95% !important;
}

.ui.inverted.top.fixed.menu {
  background-image: linear-gradient(
    135deg,
    rgb(24, 42, 115) 0%,
    rgb(33, 138, 174) 69%,
    rgb(32, 167, 172) 89%
  ) !important;
}

.masthead {
  display: flex;
  align-items: center;
  background-image: linear-gradient(
    135deg,
    rgb(24, 42, 115) 0%,
    rgb(33, 138, 174) 69%,
    rgb(32, 167, 172) 89%
  ) !important;
  height: 100vh;
}

.masthead .ui.menu .ui.button,
.ui.menu a.ui.inverted.button {
  margin-left: 0.5em;
}

.masthead h1.ui.header {
  font-size: 4em;
  font-weight: normal;
}

.masthead h2 {
  font-size: 1.7em;
  font-weight: normal;
}

.ui.form textarea {
  box-sizing: border-box !important;
}

.ui.inverted.top.fixed.stg.menu {
  background-image: linear-gradient(
    135deg,
    rgb(86, 171, 47) 0%,
    rgb(118, 184, 82) 69%,
    rgb(141, 194, 111) 89%
  ) !important;
}

.stgmasthead {
  display: flex;
  align-items: center;
  background-image: linear-gradient(
    135deg,
    rgb(86, 171, 47) 0%,
    rgb(118, 184, 82) 69%,
    rgb(141, 194, 111) 89%
  ) !important;
  height: 100vh;
}
